var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Popover,{attrs:{"placemen":"top","trigger-type":"user","custom-class":_setup.prefixCls('mp__item_popover_container')},model:{value:(_setup.delectBubbleVisible),callback:function ($$v) {_setup.delectBubbleVisible=$$v},expression:"delectBubbleVisible"}},[(_setup.delectBubbleInfo)?_c('div',[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: 'expose_ugctags_bubble:simple',
        data: _setup.delectBubblePoint,
        delay: 0
      }),expression:"{\n        id: 'expose_ugctags_bubble:simple',\n        data: delectBubblePoint,\n        delay: 0\n      }"}],class:_setup.prefixCls('mp__item_popover_text'),style:(_setup.bubbleStyle),attrs:{"id":_vm.data.id}},[_c('img',{attrs:{"src":_setup.delectBubbleInfo.icon}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_setup.delectBubbleInfo.text)}}),_vm._v(" "),_c(_setup.Icon,{class:_setup.prefixCls('mp__item_popover_close'),attrs:{"name":"sui_icon_close_16px_2","size":"16px"},on:{"handleCloseIconClick":_setup.close}})],1)]):_vm._e(),_vm._v(" "),_c('div',{class:[
      _setup.prefixCls('mp__item'),
      _setup.visibleTogetherDynamic && 'shopping-bag-dynamic__body-item'
    ],attrs:{"slot":"reference"},slot:"reference"},[_c('div',{class:_setup.prefixCls('mp__item-section')},[_c('div',{class:_setup.prefixCls('mp__is-images')},[_c(_setup.CommonImage,{class:_setup.isFirstScreenRender ? 'fsp-element' : '',attrs:{"imgUrl":_vm.data?.product?.goods_img,"imgDesignWidth":111,"isFirstPage":_setup.isFirstScreenRender,"fetchpriority":_setup.isFirstScreenRender ? 'high' : 'auto',"fsp-key":_setup.isFirstScreenRender
              ? `${_setup.prefixCls('mp__is-main-image')}_${_vm.data?.id}`
              : null,"imageMask":_vm.data?.product?.maskLayerImg},scopedSlots:_vm._u([{key:"others",fn:function(){return [_c(_setup.Sensitive,{attrs:{"id":_vm.data.id}}),_vm._v(" "),(_setup.beltInfo.isShow)?_c(_setup.Belt,{attrs:{"data":_vm.data,"renderWidth":_setup.renderWidth}}):_vm._e()]},proxy:true}])})],1),_vm._v(" "),_c(_setup.Price,{attrs:{"data":_vm.data}}),_vm._v(" "),(_setup.isShowP65Warning)?_c(_setup.P65Warning,{class:_setup.prefixCls('mp__warning'),attrs:{"goods-sn":_vm.data?.product?.goods_sn}}):_vm._e()],1),_vm._v(" "),_c(_setup.QuantityInput,{class:_setup.prefixCls('mp__input-number'),attrs:{"data":_vm.data || {}},on:{"emitInputCurrent":_setup.inputNumberChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }