import { ref, onMounted, nextTick, computed, shallowRef } from 'vue'
import { PRODUCT_TYPE, CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { useMapGetters, useMapState, useMapActions, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { payCardPrefetchFn } from 'public/src/pages/checkout_new/utils/payment_options_item_prefetch'
import { debuggerLog } from '@/public/src/pages/checkout_new/utils/index.js'

import { usePayment } from '@/public/src/pages/checkout_new/hooks/usePayment'
import useCreateOrder from '@/public/src/pages/checkout_new/hooks/useCreateOrder.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { useBsPayInstance } from '@/public/src/pages/checkout_new/hooks/useBsPayInstance'
import useCoupon from '@/public/src/pages/checkout_new/hooks/useCoupon.js'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import useReportAnalysis from '@/public/src/pages/checkout_new/hooks/useReportAnalysis.js'

export const usePaymentOptions = ({ emit, paymentOptionsConfig }) => {

  const { updateCheckoutData, updateCheckoutParams } = useMapActions(['updateCheckoutData', 'updateCheckoutParams'])
  const { 
    updateSelectedPaymentInfo, 
    updateFooterState, 
    assignPaymentState,
    updateShipPopupByKey,
    switchToNormalCard,
    updateCommonDialogState,
    mergeClickContinueResultInfoState
  } = useMapMutations([
    'updateSelectedPaymentInfo', 
    'checkoutComplete', 
    'updateFooterState', 
    'assignPaymentState',
    'updateShipPopupByKey',
    'switchToNormalCard',
    'updateCommonDialogState',
    'mergeClickContinueResultInfoState'
  ])

  const { handleClickContinueResult } = useReportAnalysis()

  assignPaymentState({ recordPaymentOptionsConfig: paymentOptionsConfig || {} })

  const isPriceDetailVisible = ref(false)
  const paymentOptionsRef = ref()
  const temporaryPrePayData = shallowRef({})

  const { paymentInfo, selectedPaymentInfo, paymentCheckoutInfo, injectFsData } = useMapGetters(['paymentInfo', 'selectedPaymentInfo', 'paymentCheckoutInfo', 'injectFsData'])
  const { couponListState, primeSaverState, paymentState, language } = useMapState([
    'couponListState', 'primeSaverState', 'paymentState', 'language'
  ])
  // const { selectedPaymentInfo } = useMapState(['language'])

  const { handleCreatePayment, getOrderDetailLink } = usePayment()
  const { paymentInstance } = useBsPayInstance()
  const { handleCreateOrder } = useCreateOrder()

  const userInfoGetter = {
    email () {
      if (typeof window === 'undefined') return ''
      debuggerLog('checkout_new====userInfoGetter====', UserInfoManager.getSync({ key: 'pwa_user_email', actionType: 'bsLibs' }) || '')
      return UserInfoManager.getSync({ key: 'pwa_user_email', actionType: 'bsLibs' }) || ''
    }
  }

  const paymentConfig = {
    productType: PRODUCT_TYPE?.NORMAL,
    checkoutType: CHECKOUT_TYPE?.NORMAL
  }

  // TODO 临时挂载到window上，用于调试
  if (typeof window !== 'undefined') {
    window.UserInfoManager = UserInfoManager
  }

  const checkoutPaymentInfo = computed(() => {
    return {
      ...paymentInfo.value,
      ...(paymentOptionsConfig?.foldedPosition != null ? { folded_position: paymentOptionsConfig?.foldedPosition } : {}),
      // paymentSuggestion: 'klarna-sliceitdirect'
    }
  })

  const noSelectPayment = computed(() => paymentState.value.noSelectPayment)


  const formatPaymentCheckoutParams = (info) => {
    debuggerLog('checkout_new====formatPaymentCheckoutParams====', info)
    const data = {
      request_card_token: 1,
      hasCardBin: 1,
      payment_code: info?.payment_code,
      payment_code_unique: info?.payment_code,
      payment_id: info?.payment_id,
      selected_bin: info?.usedCardBin || info?.card_token_info?.card_bin || '',
      usedCardBin: info?.usedCardBin || info?.card_token_info?.card_bin || '',
      token_id: info?.token_id || '',
      installment_token_id: info?.installment_token_id || '',
      cod_plan: info?.payment_code === 'cod' ? 'codB' : '',
    }

    if (info?.couponList) data.coupon_list = info?.couponList
    if (info?.isUseNewCard) {
      data.cardPaymentFront = 2 // 当新卡支付场景，客户端入参cardPaymentFront=2
    } else {
      data.cardPaymentFront = info?.cardPaymentFront || ''
    }
    return data
  }

  const handleSelectPayment = async ({ paymentInfo, changeCheckData }) => {
    debuggerLog('checkout_new====handleSelectPayment======', paymentInfo)
    const newCheckoutParams = formatPaymentCheckoutParams(paymentInfo)
    updateSelectedPaymentInfo(paymentInfo)
    emit('selected-payment', paymentInfo)
    if (!paymentInfo?.manualSelect) {
      const res = await updateCheckoutData({
        opts: {
          ...newCheckoutParams,
          ...(changeCheckData?.data || {})
        },
        scene: 'select-payment-info',
        cb: (res) => {
          changeCheckData?.cb?.(res)
        }
      })
      if (+res?.code === 0) {
        assignPaymentState({ noSelectPayment: false })
      }
      debuggerLog('checkout_new====handleSelectPayment====updateCheckoutData====success', res)
    } else {
      assignPaymentState({ noSelectPayment: false })
      // checkoutComplete({ scene: 'manualSelectPayment' })
      handleSetCheckoutPaymentParams(newCheckoutParams)
    }
    // 卡支付页资源预取
    if (typeof window !== 'undefined') {
      payCardPrefetchFn(+paymentInfo.payment_type)
    }
  }

  const handlePaymentInfoChange = (info) => {
    const paymentInfo = info.paymentInfo || {}
    const extraInfo = info.extraInfo || {}
    const newCheckoutParams = formatPaymentCheckoutParams(paymentInfo)
    updateSelectedPaymentInfo(paymentInfo)

    if (extraInfo?.isNeedPostCheckout) {
      updateCheckoutData({
        opts: {
          ...newCheckoutParams,
          ...(extraInfo?.changeCheckData?.data || {})
        },
        scene: 'payment-info-change',
        cb: (res) => {
          paymentInfo?.cb?.(res)
          extraInfo?.changeCheckData?.cb?.(res)

          if (extraInfo?.isNeedPostCoupon) {
            nextTick(() => {
              couponListState?.value?.BCouponListRef?.getCouponList({})
            })
          }
        }
      })
    }
  }

  const handleSetCheckoutPaymentParams = (params) => {
    debuggerLog('checkout_new====handlePaymentInfoChange===handleSetCheckoutPaymentParams', params)
    updateCheckoutParams({ 
      params: params
    })
  }

  const handleInitCommonMethod = (methods) => {
    debuggerLog('handleInitCommonMethod==|||==', methods)
    nextTick(() => {
      paymentInstance.bindComponentMethods(methods)
    })
  }

  const handlePayNow = async (extraInfo = {}) => {
    debuggerLog('checkout_new====handlePayNow====', extraInfo, extraInfo.cvv)
    await handleCreateOrder({ 
      extraPayInfo: { 
        cvv: extraInfo.cvv, 
        installments: extraInfo.installments || 1, 
        ...(extraInfo?.prePayUnPayParams || {}) 
      },
      extraCb: {
        errorCb: () => {
          extraInfo?.errorCb?.()
          debuggerLog('checkout_new====handlePayNow====errorCb====')
        }
      },
      extraParams: {
        coverData: {
          ...(extraInfo?.coverData || {}),
          cvv: extraInfo.cvv, 
          installments: extraInfo.installments || 1, 
        }
      },
      payAnalysisInfo: extraInfo?.payAnalysisInfo || {}
    })
    debuggerLog('checkout_new====handlePayNow====handleCreateOrder====')
  }

  const handleBsPaymentAction = async ({ action, payload }) => {
    debuggerLog('checkout_new====handleBsPaymentAction=>>===', { action, payload })

    const handlerObj = {
      updateCheckout: async () => {
        const { params = {} } = payload || {}
        const newCheckoutParams = formatPaymentCheckoutParams({
          ...selectedPaymentInfo.value,
          ...params
        })
        const res = await updateCheckoutData({
          opts: { ...newCheckoutParams },
          scene: 'payment-action-update-checkout',
        })
        debuggerLog('checkout_new====handleBsPaymentAction=>>===updateCheckout', res)
        payload.cb?.({ status: +res?.code === 0 ? 'success' : 'fail', result: res?.info || {} })
      },
      updateTotalPrice: () => {
        updateFooterState({ installmentTotalPrice: payload.totalAmountWithSymbol || '' })
      },
      appendSortedPrice: () => {
        assignPaymentState({ appendSortedPrice: payload.appendPrice || [] })
      },
      couponDrawerVisible: () => {
        updateShipPopupByKey({
          key: 'couponDrawerVisible',
          value: true
        })

        nextTick(() => {
          const { useScrollToCoupon } = useCoupon()
          useScrollToCoupon(payload?.code)
        })
      },
      prePayActiveClose: () => {
        handlePrePayActiveClose()
      },
      changePriceDetailVisible: () => {
        changePriceDetailVisible(true)
      },
      handlerEmitOrderAnalysis: () => {
        debuggerLog('checkout_new====handlerEmitOrderAnalysis====', payload.handlerEmitOrderAnalysisParams)
        handlerEmitOrderAnalysis(payload.handlerEmitOrderAnalysisParams)
      }
    }

    handlerObj?.[action]?.()
  }

  const handlePrePayActiveClose = (payload = {}) => {
    window.appEventCenter.$emit('PAYMENT_VALID_SEND_BI', {
      result_reason: '1_12'
    })
    if (!payload?.billno) return
    const { langPath } = gbCommonInfo || {}
    window.location.href = `${langPath}/user/orders/detail/${payload?.billno}`
  }

  const asyncCreateOrder = async () => {
    return new Promise((resolve, reject) => {
      handleCreateOrder({
        extraCb: {
          cancelCb: () => {
            reject()
          },
          successCb: () => {
            debuggerLog('checkout_new====asyncCreateOrder====success')
            resolve()
          },
          errorCb: (res) => {
            const { isShowPrePayModal } = paymentOptionsRef?.value?.getComponentStatus?.() || {}
            if (isShowPrePayModal) {
              paymentOptionsRef.value?.handlePrePayAction?.({ action: 'closePrePayModal' })
            }
            reject(res)
          }
        }
      })
    })
  }

  const handleCreatePaymentError = (result) => {
    debuggerLog('checkout_new====handleCreatePaymentError====', result, paymentOptionsRef.value?.handlePrePayAction)

    // 10119005 为卡bin优惠冻结操作自定义的错误码
    if (result?.code == '10119005') {
      updateCommonDialogState({
        createOrderBinDialog: {
          show: true,
          onClose: () => {
            const link = getOrderDetailLink()
            window.location.href = link
            updateCommonDialogState({
              createOrderBinDialog: {
                show: false,
                onClose: null,
                onConfirm: null
              }
            })
          },
          onConfirm: () => {
            // 清除冻结参数，继续发起支付
            temporaryPrePayData.value.freezeParamsStr = ''
            handlePrePayCheckout(temporaryPrePayData.value)

            temporaryPrePayData.value = {}
          }
        }
      })
      return
    }

    paymentOptionsRef.value?.handlePrePayAction?.({
      action: 'visiblePayFailGuide',
      payload: {
        visible: true,
        type: 'payError',
        hasGuide: result.info?.is_guide == 1,
        failText: result?.info?.show_error_msg || result?.info?.error_msg || result?.tips || '',
        closeCb: function ({ isActivelyClose } = {}) {
          const link = getOrderDetailLink({ extraParams: isActivelyClose ? {} : { showpaymethods: 1 } })
          window.location.href = link
        },
        confirmCb: async function () {
          const createdOrderInfo = paymentState.value?.createdOrderInfo || {}
          paymentOptionsRef.value?.handlePrePayAction?.({
            action: 'visiblePayFailGuide',
            payload: {
              visible: false,
              closeCb: () => {},
              confirmCb: () => {}
            }
          })
          paymentOptionsRef.value?.handlePrePayAction?.({
            action: 'startRearOrderFlow',
            payload: { billno: createdOrderInfo.relation_billno || createdOrderInfo.billno || '' }
          })
        }
      }
    })
  }

  const handleCreatePaymentComplete = (result = {}) => {
    mergeClickContinueResultInfoState({
      failure_reason: JSON.stringify({
        code: result?.code == 0 && result?.info?.error_code ? result?.info?.error_code : result?.code || '',
        result: result?.info?.result || '0'
      }),
      status: result?.code != 0 || result?.info?.error_code ? 'fail' : 'success',
    })
    handleClickContinueResult(paymentState.value.clickContinueResultInfo)
  }

  const handlePrePayCheckout = async (extraPayInfo = {}) => {
    debuggerLog('checkout_new====handlePrePayCheckout====', extraPayInfo)
    if (!extraPayInfo?.billno) {
      try {
        await asyncCreateOrder()
      } catch (error) {
        debuggerLog('checkout_new====asyncCreateOrder====error', error)
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: 'pre_pay_create_order_error',
            checkout_type: CHECKOUT_TYPE.NORMAL,
            product_type: PRODUCT_TYPE.NORMAL,
            payment_code: selectedPaymentInfo.value?.payment_code || '',
          },
          params: {
            url: location.href,
          }
        })
        return
      }
    }

    temporaryPrePayData.value = extraPayInfo

    const { validateResult, metric_scene, scene = '', failReasonResults = [], billno = '', prePayCheckoutInfo = {}, prePayUnPayParams = {}, orderInfo = {} } = extraPayInfo || {}
    if (!validateResult) {
      // 卡前置校验
      if (scene === 'pre_create_pay') {
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: metric_scene,
            checkout_type: CHECKOUT_TYPE.NORMAL,
            product_type: PRODUCT_TYPE.NORMAL,
            payment_code: selectedPaymentInfo.value?.payment_code || '',
          },
          params: {
            url: location.href,
            message: `err: ${failReasonResults?.join?.(',')}, billno: ${billno || ''}`
          },
          message: `err: ${failReasonResults?.join?.(',')}, billno: ${billno || ''}`
        })
      }
    }

    const getIsCvvLimit = (cvv = '') => {
      const cvvValIsNoLimitList = ['000', '0000', '']
      const cvvErr = failReasonResults?.find?.(v => v?.startsWith?.('cvv'))
      return cvvErr || !cvvValIsNoLimitList.includes(cvv)
    }

    const pageReadyTime = window.SaPageInfo?.start_time || Date.now()

    const createdOrderInfo = paymentState.value?.createdOrderInfo || {}

    const orderAnalysisInfo = {
      order_id_list: orderInfo?.sub_billnos?.map?.(v => v.sub_billno).join(',') || '', // 每个子订单号之间用 隔开
      order_no: extraPayInfo?.billno || '',
      payment_method: orderInfo?.payment_method || '',
      uorder_id: extraPayInfo?.relation_billno || extraPayInfo?.billno || '', // 关联订单号 
    }

    // 首次支付时的埋点信息
    if (!extraPayInfo?.billno) {
      orderAnalysisInfo.order_no = createdOrderInfo?.billno || createdOrderInfo?.relation_billno || ''
      orderAnalysisInfo.uorder_id = createdOrderInfo?.relation_billno || ''
      orderAnalysisInfo.order_id_list = createdOrderInfo?.order_group?.map?.(v => v.billno).join(',') || ''
      orderAnalysisInfo.payment_method = createdOrderInfo?.payment_method || ''
    }


    // 单后埋点信息
    mergeClickContinueResultInfoState({
      failure_reason: !validateResult ? JSON.stringify({
        code: '-',
        result: failReasonResults?.join?.(',') || ''
      }) : {},
      failure_type: 'front_end',
      scene: 'new',
      instalment_num: prePayUnPayParams?.installments || '',
      is_remember: prePayUnPayParams?.rememberCard ? '1' : '0',
      is_need_cvv: '-',
      is_cvvnolimit: getIsCvvLimit(prePayCheckoutInfo?.cvv) ? '0' : '1',
      stay_time: parseInt((Date.now() - pageReadyTime) / 1000),
      ...orderAnalysisInfo
    })

    if (!validateResult) {
      mergeClickContinueResultInfoState({
        status: 'fail',
      })
      handleClickContinueResult(paymentState.value.clickContinueResultInfo)
      return
    }

    mergeClickContinueResultInfoState({
      failure_type: 'rear_end', // 失败类型 front_end/rear_end
      instalment_num: prePayUnPayParams?.installments || '',
      is_remember: prePayUnPayParams?.rememberCard ? '1' : '0',
      scene: 'new',
      status: 'success',
    })

    handleCreatePayment({ extraPayInfo: { ...(extraPayInfo?.prePayUnPayParams || {}), freezeParamsStr: extraPayInfo?.freezeParamsStr || '' }, extraCb: {
      onError: handleCreatePaymentError,
      onComplete: handleCreatePaymentComplete
    } })
  }

  const handleCheckoutFooterBtnClick = async (checkoutFooterProps) => {
    debuggerLog('checkout_new====handleCheckoutFooterBtnClick====', checkoutFooterProps)
    if (checkoutFooterProps?.scene === 'pre-pay') {
      checkoutFooterProps?.payNow?.(handlePrePayCheckout)
    }
  }

  const changePriceDetailVisible = visible => {
    isPriceDetailVisible.value = visible
  }

  const handleBeforeSelect = (preSelectPaymentInfo) => {
    switchToNormalCard(preSelectPaymentInfo)
    return !primeSaverState.value.switchCardDrawer.show
  }

  const setSelectedPayment = (info) => {
    debuggerLog('checkout_new====setSelectPayment====', info)
    paymentOptionsRef.value?.setSelectedPayment?.(info)
  }

  // 获取支付组件内部保存的状态
  const getPaymentOptionsLocalState = () => {
    const localState = paymentOptionsRef.value?.getEditablePaymentInfoData?.()
    debuggerLog('checkout_new====getPaymentOptionsLocalState====', localState)
    return localState
  }

  const resetSelectedPayment = (needEmitEvent) => {
    paymentOptionsRef.value?.resetSelectedPayment?.(needEmitEvent)
  }

  const setSelectedPaymentForBinCoupon = ({
    paymentCode,
    tokenItem,
    changeCheckData,
  }) => {
    paymentOptionsRef.value?.setSelectedPaymentForBinCoupon?.({
      paymentCode,
      tokenItem,
      changeCheckData,
    })
  } 

  const scrollToPaymentByCode = (paymentCode) => {
    paymentOptionsRef.value?.scrollToPaymentByCode?.(paymentCode)
  }

  // 支付组件内部抛出来的方法注册到 store 中
  const initStorePayInstanceEvent = () => {
    nextTick(() => {
      const getIsShowPrePayModal = () => {
        return paymentOptionsRef.value?.getComponentStatus?.()?.isShowPrePayModal
      }

      debuggerLog('paymentOptionsRef.value >>>>>', paymentOptionsRef.value)
      window.PAY_M = paymentOptionsRef.value
      assignPaymentState({ payInstanceEvent: Object.assign({}, paymentState.value.payInstanceEvent, {
        getIsShowPrePayModal: getIsShowPrePayModal, // 获取目前是否展开了卡支付前置
        getComponentStatus: paymentOptionsRef.value?.getComponentStatus,
        handlerPrePayCouponDrawer: paymentOptionsRef.value?.handlerPrePayCouponDrawer, // 获取目前是否展开了卡支付前置卡 bin 优惠券不可用抽屉
        getValidateByPay: paymentOptionsRef.value?.getValidateByPay,
      }) })
    })
  }

  const handlerEmitOrderAnalysis = (params) => {
    window?.appEventCenter?.$emit?.('PAYMENT_VALID_SEND_BI', params)
  }

  onMounted(() => {
    if (!window.appEventCenter) {
      window.appEventCenter = new Vue()
    }
    // hack 提前拉一次用户邮箱，避免在支付组件中同步获取用户信息时拿不到
    UserInfoManager.get({ key: 'pwa_user_email', actionType: 'bsLibs' }) || ''

    appEventCenter.$off('set-selected-payment', setSelectedPayment)
    appEventCenter.$off('set-selected-payment-for-coupons', setSelectedPaymentForBinCoupon)
    appEventCenter.$off('scroll-to-payment-by-code', scrollToPaymentByCode)

    appEventCenter.$on('set-selected-payment', setSelectedPayment)
    appEventCenter.$on('set-selected-payment-for-coupons', setSelectedPaymentForBinCoupon)
    appEventCenter.$on('scroll-to-payment-by-code', scrollToPaymentByCode)
    appEventCenter.$on('reset-selected-payment', resetSelectedPayment)
    initStorePayInstanceEvent()
  })

  return {
    checkoutPaymentInfo,
    paymentCheckoutInfo,
    injectFsData,
    couponListState,
    language,
    userInfoGetter,
    paymentConfig,
    isPriceDetailVisible,
    noSelectPayment,
    handleSelectPayment,
    handlePaymentInfoChange,
    handleInitCommonMethod,
    handlePayNow,
    handleBsPaymentAction,
    handleCheckoutFooterBtnClick,
    handleBeforeSelect,
    changePriceDetailVisible,
    setSelectedPayment,
    setSelectedPaymentForBinCoupon,
    resetSelectedPayment,
    scrollToPaymentByCode,
    paymentOptionsRef,
    handlerEmitOrderAnalysis,
    getPaymentOptionsLocalState
  }

}
