<template>
  <div
    v-if="!!quickShipInfo.visible"
    :class="prefixCls('common-label_quick-ship')"
    @click.stop="showQuickPop"
  >
    <!-- label标签 -->
    <LabelTag
      :tag="quickShipInfo.productTags"
      useIcon
    />
    <div :class="prefixCls('common-label_qs-line')"></div>
    <span
      :class="prefixCls('common-label_qs-desc')"
      v-html="quickShipInfo.quickShippingDesc"
    >
    </span>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { template as _commonTemplate } from '@shein/common-function'

import LabelTag from '@/public/src/pages/checkout_new/components/QuickShipTag.vue'
// --------- useMap_**** --------
import {
  useMapState,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const mallIndex = inject('mallIndex')
// --------- useMap_**** --------
const { checkout, shoppingBagsState, locals } = useMapState(['checkout', 'shoppingBagsState', 'locals'])
const { updateShipPopupState } = useMapMutations(['updateShipPopupState'])
// ---------- computed ----------
/**
 * @description 渲染数据
 * @returns {productTags} label标签
 * @returns {itemsLabel} desc文案
 * */
const quickShipInfo = computed(() => {
  const { quick_shipping } = checkout.value
  const { new_quick_shipping_tag_desc, quick_shipping_time_desc, multi_mall_code } = quick_shipping || {}
  const currentMallCode = shoppingBagsState.value.bagsInfo?.mall[mallIndex].mall_code

  const specialCountryMap = {
    mbr: currentMallCode == multi_mall_code
  }
  const specialSiteResult = Object.keys(specialCountryMap).includes(locals.value.SiteUID) ? specialCountryMap[locals.value.SiteUID] : true

  return {
    productTags:
      {
        textColor: '',
        bgColor: '',
        prefixIcon: '',
        text: new_quick_shipping_tag_desc || '',
        IconSize: '15px',
        themTextStyle: {
          'font-style': 'italic',
          color: 'rgb(25, 128, 85)',
          'font-weight': '590',
          'font-size': '12px'
        }
      } || {},
    quickShippingDesc: quick_shipping_time_desc || '',
    visible: specialSiteResult && !!new_quick_shipping_tag_desc && !!quick_shipping_time_desc
  }
})


const showQuickPop = () => {
  const {
    quick_shipping_title,
    quick_shipping_new_tip,
    quick_shipping_content_fill_label_desc
  } = checkout.value.quick_shipping?.quick_shipping_tip || {}

  const content = _commonTemplate(
    `<div style="display: inline-flex; align-items: center; background: #ECFCF3; color: #198055; border: 1px solid #BADECD; border-radius: 2px; font-family: SF Pro; font-style: italic; font-size: 12px; padding: 0 4px;"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 13px; height: 13px; margin-right: 2px;">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.85326 4.86768H20.1563L18.9882 8.94119L23.8293 10.3241L21.3254 18.9768L18.6339 18.9766C18.1351 19.7664 17.1971 20.3362 16.284 20.3362C15.372 20.3362 14.7611 19.7677 14.714 18.9793H8.59125C8.09195 19.7677 7.15505 20.3362 6.24303 20.3362C5.33101 20.3362 4.72012 19.7677 4.67297 18.9793H2.80683L4.10148 14.4643H0.156128L0.707403 12.5417L11.9123 12.5411L13.5661 6.77351H6.30677L6.85326 4.86768ZM22.0777 11.3693L18.5302 10.4507L17.2787 14.4164H21.1448L22.0777 11.3693Z" fill="#198055"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0148 8.69598H3.67555L3.12427 10.6185H10.4635L11.0148 8.69598Z" fill="#198055"></path>
</svg><spin style="font-weight: 500">${quick_shipping_content_fill_label_desc}</spin></div>`,
    quick_shipping_new_tip
  )

  updateShipPopupState({
    quickShipPopup: {
      visible: true,
      label: quick_shipping_title || '',
      content
    }
  })
}
</script>

<style lang="less">
@import '../..//variables.less';

.@{prefixCls}common-label {
  overflow: hidden;
  &_quick-ship {
    background: #ecfcf3;
    padding: 8/75rem 16/75rem;
    border: 1px solid #badecd;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin: 0.16rem 0;
  }

  &_qs {
    &-desc {
      flex: 1;
      line-height: 14px;
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: rgb(25, 128, 85);
    }

    &-line {
      margin-left: 8px;
      margin-right: 6px;
      background: #8dc2ab;
      width: 1px;
      height: 0.4rem;
    }
  }
}
</style>
