<template>
  <div 
    v-if="primeInfo?.prime_products?.length > 0"
    class="checkout-prime"
  >
    <BffClub
      class="checkout-prime__wrap"
      :language="language"
      :prime-info="primeInfo"
      :not-support-tip="selectedPaymentInfo.not_support_prime_tip"
      :used-prime-index="primeSaverState.selectedPrimeIndex"
      :detail-drawer-props="detailDrawerProps"
      :goto-coupon-list="handleCouponListOpen"
      :on-prime-select="handlePrimeSelect"
    />

    <ClientOnly>
      <QuickPaymentDrawer
        v-if="drawerPrimeIndex > -1" 
        :visible.sync="quickPaymentDrawerVisible"
        :card-type="CardType.PRIME"
        v-bind="quickPaymentDrawerData"
        :style="{
          '--drawer-background': 'linear-gradient(279deg, #FFDCB2 12.71%, #FFF4D7 32.38%, #FFECD6 52.47%, #FFE1BE 91.42%)'
        }"
        @click-confirm="handlePaymentConfirm"
      />
    </ClientOnly>
  </div>
</template>
<script>
export default {
  name: 'CheckoutPrime'
}
</script>
<script setup name="CheckoutPrime">
import ClientOnly from 'vue-client-only'
import { ref, computed, defineAsyncComponent } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState, useMapGetters, useMapMutations } from 'public/src/pages/checkout_new/hooks/store'
import useQuickPayments from './hooks/useQuickPayments.js'
import useUpdateCardData from './hooks/useUpdateCardData.js'
import { CardType, DaRenewType, SelectPrimeLocation, PrimeDrawerLocation } from './utils/constant.js'

import BffClub from 'public/src/pages/common/product-package/club/bff/ClubProductPackageWrapper.vue'
const QuickPaymentDrawer = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-quick-payment-drawer" */ './components/quick_payment_drawer/Index.vue')
)

const { 
  language,
  primeSaverState,
  checkoutParams,
  prime_info
} = useMapState([
  'language',
  'primeSaverState',
  'checkoutParams',
  'checkout.prime_info', 
])
const { 
  selectedPaymentInfo,
  selectedPrimeProduct
} = useMapGetters([
  'selectedPaymentInfo',
  'selectedPrimeProduct'
])
const { updateShipPopupByKey, updatePrimeSaverState } = useMapMutations(['updateShipPopupByKey', 'updatePrimeSaverState'])
const { isQuickPayment, filterBubbleQuickPayments } = useQuickPayments()
const { updateCardData } = useUpdateCardData()

const drawerPrimeIndex = ref(-1)
const quickPaymentDrawerVisible = ref(false)

// 当前选中的支付方式是否支持快捷支付
const isQuickPaySupported = computed(() => isQuickPayment({ 
  cardType: CardType.PRIME, 
  payment: selectedPaymentInfo.value,
}))
const primeInfo = computed(() => ({
  ...prime_info.value,
  prime_products: prime_info.value?.prime_products?.map(item => {
    const { is_auto_renew_product, pick_popup_show, pick_popup_payment_list } = item.auto_renewal || {}
    let popupVisible = pick_popup_show
    // 仅处理自动续费产品包，及需要展示气泡的产品包
    if (+is_auto_renew_product === 0 || pick_popup_payment_list?.length === 0) return item
    // 如果当前支付方式支持自动续费，不展示快捷支付方式气泡
    popupVisible = isQuickPaySupported.value ? 0 : 1
    if (popupVisible && pick_popup_payment_list?.length > 0) {
      item.auto_renewal.filteredPaymentList = filterBubbleQuickPayments({ 
        cardType: CardType.PRIME, 
        payments: pick_popup_payment_list
      })
    }
    if (item.auto_renewal) item.auto_renewal.pick_popup_show = popupVisible
    return item
  })
}))

const detailDrawerProps = computed(() => {
  return {
    opened: primeSaverState.value.primeDetailsDrawerVisible,
    setOpened: handlePrimeDetailsDrawer,
    onOk: (index) => handlePrimeSelect(index, SelectPrimeLocation.DRAWER),
    onBubbleClick: showQuickPaymentDrawer,
    onCloseFromIcon: () => closePrimeDetailsDrawer({ type: 'close' })
  }
})

const quickPaymentDrawerData = computed(() => {
  const productInfo = primeInfo.value?.prime_products?.[drawerPrimeIndex.value]
  if (!productInfo) return {}
  
  const { auto_renewal = {}, product_price_info } = productInfo || {}
  const { title, title_saving_price, fee_desc, current_payment_disable_desc, change_payment_title, force_remember_card_tip } = auto_renewal.prime_popup || {}
  const { is_display_origin_price, price_local_with_symbol, special_price_with_symbol } = product_price_info || {}
  return {
    index: drawerPrimeIndex.value,
    productCode: productInfo.product_code,
    from: detailDrawerProps.value.opened ? SelectPrimeLocation.DRAWER : SelectPrimeLocation.PAGE,
    headerProps: {
      title: template(
        `<span style="color:#F82854;font-size: 32px;font-weight: 860;">${title_saving_price}</span>`,
        title
      ),
      subtitle: `${fee_desc} <span style="font-weight: 700;">${special_price_with_symbol}</span>`,
      originPrice: +is_display_origin_price ? price_local_with_symbol : '',
    },
    disableDesc: current_payment_disable_desc,
    quickPaymentProps: {
      title: change_payment_title,
      forceRememberCardTips: force_remember_card_tip
    }
  }
})

const handleCouponListOpen = () => {
  updateShipPopupByKey({
    key: 'couponDrawerVisible',
    value: true
  })
}

const handlePrimeSelect = (index, location = SelectPrimeLocation.PAGE) => {
  const productInfo = primeInfo.value.prime_products[index]
  if (!productInfo) return
  const isCancel = checkoutParams.value.prime_product_code === productInfo.product_code
  const { is_auto_renew_product, prime_popup } = productInfo.auto_renewal || {}
  if (isCancel || !+is_auto_renew_product || isQuickPaySupported.value || !prime_popup) {
    closePrimeDetailsDrawer({ type: 'ok', selectedProduct: productInfo })
    updateCardData({
      cardType: CardType.PRIME,
      primeIndex: index,
      selectPrimeLocation: location,
      params: {
        prime_product_code: isCancel ? '' : productInfo.product_code
      }
    })
  } else {
    // 当前支付方式非快捷支付方式，需要切换
    showQuickPaymentDrawer(index)
  }
}

const handlePaymentConfirm = (index) => {
  // 关闭详情弹窗
  setTimeout(() => closePrimeDetailsDrawer(), 300)
}

const showQuickPaymentDrawer = (index) => {
  drawerPrimeIndex.value = index
  quickPaymentDrawerVisible.value = true
  daEventCenter.triggerNotice({
    id: 'expose_autorenew_restriction:simple',
    data: {
      renew_type: DaRenewType[CardType.PRIME]
    }
  })
}

const closePrimeDetailsDrawer = (data = {}) => {
  if (!detailDrawerProps.value.opened) return
  const { type, selectedProduct = selectedPrimeProduct.value } = data
  updatePrimeSaverState({ primeDetailsDrawerVisible: false })
  if (!type) return
  daEventCenter.triggerNotice({
    id: 'click_prime_popup:simple',
    data: {
      click_type: type, // ok/close
      prime_level: selectedProduct?.product_cycle_type || 0,
      select_product_activity_type: `${selectedProduct?.product_activity_type || ''}`,
      location: primeSaverState.value.primeDetailsDrawerLocation
    }
  })
}

const handlePrimeDetailsDrawer = (visible) => {
  if (visible) {
    updatePrimeSaverState({ 
      primeDetailsDrawerVisible: true,
      primeDetailsDrawerLocation: PrimeDrawerLocation.PAGE,
    })
  } else {
    updatePrimeSaverState({ 
      primeDetailsDrawerVisible: false
    })
  }
}
</script>
