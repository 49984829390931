<template>
  <div
    v-if="freeShippingAddItem?.show_tip?.tip"
    v-expose="getAnalyzeExpose"
    :class="prefixCls('add-item')"
  >
    <!-- typeB样式 -->
    <div
      v-if="getAddEntranceDiffType === 'typeB'"
      :class="prefixCls('add-item__typeB')"
      @click.stop="handleAddFreeClick"
    >
      <div
        :class="[prefixCls('add-item__typeB_left-wrap'), prefixCls('add-item__two-lines-omit')]"
        v-html="hints"
      >
      </div>
      <Icon
        :class="prefixCls('add-item__typeB_right-wrap')"
        :name="!GB_cssRight ? 'sui_icon_more_right_12px' : 'sui_icon_more_left_12px'"
        size="14px"
        color="#198055"
      />
    </div>
    <!-- typeC样式 -->
    <div
      v-else-if="getAddEntranceDiffType === 'typeC'"
      :class="prefixCls('add-item__typeC')"
    >
      <span
        :class="[prefixCls('add-item__typeC_desc'), prefixCls('add-item__two-lines-omit')]"
        v-html="hints"
      >
      </span>
    </div>
    <!-- 默认的，含有typeA -->
    <div
      v-else
      :class="prefixCls('add-item__default')"
      @click.stop="handleAddFreeClick"
    >
      <!-- 左边 -->
      <div :class="prefixCls('add-item__default-left-tips')">
        <div
          v-if="freeThresholdTip"
          :class="prefixCls('add-item__typeA-save')"
        >
          <Icon
            name="sui_icon_freeshipping_flat_24px"
            size="14px"
            color="#fff"
          />
          <span :class="prefixCls('add-item__typeA-save_tips')"> {{ freeThresholdTip }}</span>
        </div>
        <span
          :class="[prefixCls('add-item__default-desc'), prefixCls('add-item__typeA-desc')]"
          v-html="hints"
        >
        </span>
      </div>
      <!-- 右边 -->
      <Icon
        v-if="getAddEntranceDiffType === 'typeA'"
        :name="!GB_cssRight ? 'sui_icon_more_right_12px' : 'sui_icon_more_left_12px'"
        size="12px"
        color="#767676"
      />
      <span
        v-else
        :class="prefixCls('add-item__default-right-btn')"
      >
        {{ language.SHEIN_KEY_PWA_29778 }}
      </span>
    </div>
  </div>
</template>

<script setup name="AddItem">
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Icon } from '@shein-aidc/icon-vue2'
import { prefixCls } from '../../utils/index'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useAddOrder from '@/public/src/pages/checkout_new/hooks/useAddOrder.js'

const { abtState, cartsInfo, GB_cssRight } = useMapState(['abtState', 'cartsInfo', 'GB_cssRight'])


const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  transportType: {
    type: String,
    default: ''
  },
  freeShippingAddItem: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  shippingPrices: {
    type: Object,
    default: () => ({})
  }
})

const isSingleMall = computed(() => {
  return !cartsInfo.value?.is_multi_mall
})

const getAddEntranceDiffType = computed(() => {
  if (isSingleMall.value) {
    return abtState.value?.addFreeShippingNewEntrance || ''
  }
  return ''
})

const freeThresholdTip = computed(() => {
  return getAddEntranceDiffType.value === 'typeA' && props.freeShippingAddItem?.show_tip?.freeThresholdTip
})

const hints = computed(() => {
  const { tip, replaceText = [] } = props.freeShippingAddItem?.show_tip || {}
  const args = replaceText?.map(item => {
    if (getAddEntranceDiffType.value === 'typeB' || getAddEntranceDiffType.value === 'typeC') {
      return item.text
    }
    if (getAddEntranceDiffType.value === 'typeA') {
      return `<span style="color:${item.color}; font-weight: 600">${item.text}</span>`
    }
    return `<span style="color:${item.color}; font-weight: ${item.bold ? 'bold' : 'normal'}">${item.text}</span>`
  })

  return template(...args, tip)
})

const addItemAnalyzeData = computed(() => {
  const addItem = props.freeShippingAddItem || {}
  
  if (getAddEntranceDiffType.value === 'typeC') {
    return {
      shipping_method: props.transportType || '-',
      class: addItem?.add_item_type == 1 ? 'coupon' : addItem?.add_item_type == 2 ? 'activity' : '-',
      origin_price: addItem?.threshold_price_symbol || '-',
    }
  }
  if (getAddEntranceDiffType.value === 'typeA' || getAddEntranceDiffType.value === 'typeB') {
    return {
      type: abtState.value?.addFreeShippingNewEntrance,
      shipping_method: props.transportType || '-',
      diff_price: addItem?.diffPrice?.amountWithSymbol || '-',
      class: addItem?.add_item_type == 1 ? 'coupon' : addItem?.add_item_type == 2 ? 'activity' : '-',
    }
  }
  return {
    mall_code: props.mallCode || '-',
    shipping_method: props.transportType || '-',
    type: addItem.add_item_type === 1 ? 'coupon' : 'activity',
    goods_range: addItem.free_shipping_range || '-',
    progress: addItem?.add_item_popup_info?.progressPercent,
    origin_price: addItem.goodsPrice || '-',
  }
})

// 曝光的数据
const getAnalyzeExpose = computed(() => {
  // 运输方式免邮门槛曝光，没有凑单入口
  if (getAddEntranceDiffType.value === 'typeC') {
    return {
      id: 'expose_shipping_doorsill:simple',
      data: addItemAnalyzeData.value
    }
  }
  // 运输方式凑单入口曝光
  if (getAddEntranceDiffType.value === 'typeA' || getAddEntranceDiffType.value === 'typeB') {
    return {
      id: 'expose_add_shipping:simple',
      data: addItemAnalyzeData.value
    }
  }
  return {
    id: 'expose_shipping_add:simple',
    data: addItemAnalyzeData.value
  }
})

const handleAnalyzeAddClick = () => {
  // 运输方式凑单入口点击	
  if (getAddEntranceDiffType.value === 'typeA' || getAddEntranceDiffType.value === 'typeB') {
    daEventCenter.triggerNotice({
      id: 'click_add_shipping:simple',
      data: addItemAnalyzeData.value
    })
    return
  }

  daEventCenter.triggerNotice({
    id: 'click_shipping_add:simple',
    data: addItemAnalyzeData.value
  })
}

// 免邮凑单弹窗 activity（活动免邮凑单） | coupon（优惠券免邮凑单）
const handleAddFreeClick = async (e) => {
  e.stopPropagation()
  handleAnalyzeAddClick()

  const { handleAddFreeOpen } = useAddOrder({ 
    freeShippingAddItem: props.freeShippingAddItem,
    mallCode: props.mallCode,
    transportType: props.transportType,
  })
  handleAddFreeOpen()
}
</script>

<style lang="less">
@import "../../variables.less";
.@{prefixCls}add-item {
  &__two-lines-omit {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
  }

  &__typeB {
    display: flex;
    align-items: center;
    &_left-wrap {
      flex: 1;
      color: var(---sui_color_safety, #198055);
      font-family: "SF Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
    }
    &_right-wrap {
      flex-shrink: 0;
      align-self: center;
    }
  }

  &__typeC {
    display: flex;
    align-items: center;
    &_desc {
      color: var(---sui_color_safety, #198055);
      font-family: "SF Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
    }
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 8/75rem 16/75rem;
    background: #F3FAF6;
    .font-dpr(24px);
    font-weight: 600;
    &::before {
      position: absolute;
      width: 0;
      height: 0;
      content: '';
      border-left: 10/75rem solid transparent; /* 10px宽的一半 */
      border-right: 10/75rem solid transparent; 
      border-bottom: 8/75rem solid black; /* 4px高 */
      border-color: #F3FAF6;
      top: 0;
      .left(20px);
      transform: translateY(-100%);
    }
  }

  &__default-left-tips {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .margin-r(8/75rem);
  }

  &__typeA-save {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 32/75rem;
    padding: 2/75rem 6/75rem;
    margin-right: 8/75rem;
    border-radius: 4/75rem;
    border: 1/75rem solid rgba(25, 128, 85, 0.20);
    background: var(---sui_color_safety, #198055);
    float: left;
    &_tips {
      margin-left: 4/75rem;
      color: var(---sui_color_white, #FFF);
      font-family: "SF Pro";
      font-size: 10px;
      font-weight: 510;
      line-height: 100%;
    }
  }

  &__typeA-desc {
    font-weight: 600;
    line-height: 17px;
  }

  &__default-right-btn {
    display: inline-block;
    padding: 8/75rem 20/75rem;
    min-width: 100/75rem;
    max-width: 140/75rem;
    border-radius: 100px;
    border: 1px solid @sui_color_brand;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
