<template>
  <!-- <BPaypalGaButton
    :createPaymentOptions="createPaymentOptions"
    :currency="createPaymentOptions.currency"
    :paypalStyle="paypalStyle"
    :onClick="handlePpgaClick"
    :onApproved="handlePpgaApproved"
    :onCancel="handlePpgaCancel"
    :onError="handlePpgaError"
    @info-change="handlePpgaInfoChange"
  >
  </BPaypalGaButton> -->
  <div 
    :class="[randomKey]"
  >
  </div>
</template>

<script setup name="PaypalBtn">
import { ref, computed } from 'vue'
// import { BPaypalGaButton } from '@shein-aidc/bs-pay-toolkit-mobile-vue2'
import { useMapGetters, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { PaypalGaManager, bsPayEventBus } from '@shein-aidc/bs-sdk-libs-pay'
import { paypalChannelMap } from '@/public/src/pages/checkout_new/pages/footer/utils/constant.js'
import { debuggerLog } from '@/public/src/pages/checkout_new/utils/index.js'

const isDev = typeof window !== 'undefined' ? ['debug', 'localhost'].includes(window?.gbCommonInfo?.NODE_SERVER_ENV) : false
const { selectedPaymentInfo } = useMapGetters(['selectedPaymentInfo'])
const { checkout } = useMapState(['checkout'])

const props = defineProps({
  handleCreateOrder: {
    type: Function,
    required: true
  },
  handlePayError: {
    type: Function,
    required: true
  },
})
const emit = defineEmits(['click', 'approved', 'handle-paypal-btn-init'])

const paypalStyle = {
  layout: 'horizontal',
  color: 'black',
  shape: 'rect',
  label: 'paypal',
  height: 44
}

const paypalDeviceId = ref('')
const randomKey = `pp-render-btn-${Math.random().toString(36).substring(8)}`

const createPaymentOptions = computed(() => {
  const { orderCurrency, total_price_info } = checkout.value
  const config =  {
    flow: 'checkout',
    amount: total_price_info?.grandTotalPrice?.amount,
    currency: orderCurrency?.code,
    intent: 'capture',
    enableShippingAddress: false,
  }
  if (selectedPaymentInfo.value?.code === paypalChannelMap.PAYPAL) {
    config['requestBillingAgreement'] = !!selectedPaymentInfo.value?.signupFlag || !selectedPaymentInfo.value?.use_one_time_sign
  }
  return config
})

const sdkConfig = computed(() => {
  const { address } = checkout.value
  if (selectedPaymentInfo.value?.code === paypalChannelMap.PAYPAL) {
    return {
      intent: 'capture',
      currency: createPaymentOptions.value?.currency,
      components: 'buttons,messages',
    }
  } else if(selectedPaymentInfo.value?.code === paypalChannelMap.PAYLATER) {
    const config = {
      intent: 'capture',
      currency: createPaymentOptions.value?.currency,
      components: 'buttons,messages',
      'enable-funding': 'paylater',
      dataAttributes: {
        amount: createPaymentOptions.value?.amount,
      }
    }
    if(isDev) {
      config['buyer-country'] = address?.countryCode
    }
    return config
  } else {
    return {
      components: 'messages',
    }
  }
})

const fundingSource = computed(() => {
  return selectedPaymentInfo.value?.code === paypalChannelMap.PAYPAL ? 'paypal' : 'paylater'
})

const handlePpgaApproved = (data) => {
  debuggerLog('handlePpgaApproved====', data)
  emit('approved', { sessionId: data.nonce, channelDeviceFingerId: paypalDeviceId.value })

}

const handlePpgaCancel = (data) => {
  debuggerLog('handlePpgaCancel', data)
  props?.handlePayError()
}

const handlePpgaError = (data) => {
  debuggerLog('handlePpgaError', data)
  props?.handlePayError()
}

const handleRenderComplete = () => {}

// const handlePpgaInfoChange = (data) => {
//   console.log('handlePpgaInfoChange', data)
//   paypalDeviceId.value = data.paypalDeviceId
// }

const handlePpgaClick = async (data, actions) => {
  debuggerLog('handlePpgaClick', data, actions)
  try {
    await createOrder()
    return actions.resolve()
  } catch (error) {
    debuggerLog('createOrder error', error)
    return actions.reject()
  }
}

const createOrder = () => {
  return new Promise((resolve, reject) => {
    props.handleCreateOrder({
      extraCb: {
        cancelCb: (err) => {
          debuggerLog('cancelCb', err)
          reject()
        },
        successCb: () => {
          resolve()
        },
        errorCb: (err) => {
          debuggerLog('errorCb', err)
          reject()
        }
      }
    })
  })
}

const initializePPGA = async (paySessionInfo) => {
  const ppgaSessionInfo = paySessionInfo?.[PaypalGaManager?.PAYMENT_CODE ?? ''] || {}
  if (ppgaSessionInfo.clientToken) {
    await PaypalGaManager.initPPGAPay({
      clientToken: ppgaSessionInfo.clientToken,
      profileId: ppgaSessionInfo.profileId,
    })
    const deviceData = await PaypalGaManager.getPaypalDeviceInfo(ppgaSessionInfo.clientToken)
    paypalDeviceId.value = deviceData || ''
  }
}

const loadPaypalGaBtn = () => {
  PaypalGaManager.loadPaypalGaBtn({
    createPaymentOptions: createPaymentOptions.value,
    sdkConfig: sdkConfig.value,
    fundingSource: fundingSource.value,
    paypalStyle
  })
}

PaypalGaManager.registerPPGAPay({
  onClick: handlePpgaClick,
  onApproved: handlePpgaApproved,
  onCancel: handlePpgaCancel,
  onError: handlePpgaError,
  onComplete: handleRenderComplete,
  ele: `.${randomKey}`,
})

bsPayEventBus?.paypalLoadStatus?.on(({ status }) => {
  if (status === 'RENDER_PAYPAL_BUTTON_SUCCESS') {
    emit('handle-paypal-btn-init', true)
  } else if(status === 'RENDER_PAYPAL_BUTTON_ERROR') {
    emit('handle-paypal-btn-init', false)
  }
})

bsPayEventBus?.updatePaymentInfo?.on?.(({ paySessionInfo }) => {
  emit('handle-paypal-btn-init', false)
  const isPaypalChannel = [paypalChannelMap.PAYPAL, paypalChannelMap.PAYLATER].includes(selectedPaymentInfo.value?.code)
  // paypalCheckoutInstance 已加载
  if(!!PaypalGaManager.paypalGaInstance){
    isPaypalChannel && loadPaypalGaBtn()
    return
  }
  initializePPGA(paySessionInfo).then(() => {
    isPaypalChannel ? loadPaypalGaBtn() : PaypalGaManager.loadPaypalMessage({ sdkConfig: sdkConfig.value })
  })
})

</script>

<style lang="less">
@import '../../../../../variables.less';

.@{prefixCls}venmo-btn {
  border-radius: 6/75rem;
  border-color: transparent;

  &-img {
    height: 26/75rem;
  }
}
</style>
