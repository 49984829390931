import { reactive, computed } from 'vue'
import { cloneDeep } from 'lodash'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import AddOnItemPop  from 'public/src/pages/common/addOnItem/index.js' // 促销和免邮凑单
import AddOn from 'public/src/pages/common/add-on/index.js'
import { updateCartsAndCheckout } from '@/public/src/pages/checkout_new/utils/store'
import { getShippingContentInfo } from '@/public/src/pages/checkout_new/pages/shipping_method/utils/getActivityAddItem'

import { useShippingSelect } from '@/public/src/pages/checkout_new/pages/shipping_method/hooks/useShippingSelect.js'

// 凑单相关逻辑
export default ({ freeShippingAddItem, mallCode, transportType }) => {
  const { buyNowNo, quick_shipping, bagsInfo, free_mall_info, shippingMethodState } = useMapState(['buyNowNo', 'checkout.quick_shipping', 'shoppingBagsState.bagsInfo', 'checkout.free_mall_info', 'shippingMethodState'])
  const { selectShippingMethod } = useMapMutations(['selectShippingMethod'])

  const { updateCheckoutData, getShippingMethodsParams, resetNormalOnSuccess, addItemRollback } = useShippingSelect()

  const model = reactive({
    visible: false,
    addGoodsTimes: 0,
    addFreeStatus: 0
  })

  const isCouponType = computed(() => {
    return +freeShippingAddItem?.add_item_type === 1
  })

  const goodsList = computed(() => {
    return bagsInfo.value.all?.map(item => {
      return {
        cate_id: item.product.cate_id,
        goods_id: item.product.goods_id
      }
    })
  })

  const propsParamsResult = async () => {
    const goodsIdList = goodsList.value.map(item => item.goods_id) // 商品ID
    const cateIdList = goodsList.value.map(item => item.cate_id) // 类目ID

    if(isCouponType.value) {
      const couponCode = freeShippingAddItem?.coupon_code || ''
      if(!couponCode) throw new Error('couponCode no Data')

      return  {
        props: {
          type: 'coupon',
          coupon: couponCode,
          config: {
            isBuyNow: !!buyNowNo.value,
            casualCheckoutNo: buyNowNo.value,
            continueBtnToClose: true,
            continueBtnUseLangKey: 'SHEIN_KEY_PWA_30444',
            isCloseBusinessCartEntry: true,
            isCloseIncentivePoint: true,
          },
          queryInfo: {
            addOnType: 6,
            sceneId: 152,
            goodsIds: goodsIdList,
            cateIds: cateIdList,
            quickship_prior: +quick_shipping.value.current_quick_ship_self_status ? 1 : 0
          },
          saInfo: {
            promotion_code: couponCode,
            activity_from: 'checkout_shipping_coupon_add',
            abtest: 'addnewhotJson',
            state: 'checkout_shipping_coupon_add'
          }
        }
      }
    }

    const freeMallInfo = getShippingContentInfo(free_mall_info.value)

    const activityFreeParams = {
      cartsApiData: {
        carts: goodsList.value.map(product => ({ product }))
      },
      mallCode,
      mallShippingInfo: freeMallInfo,
      isQuickship: +quick_shipping.value.current_quick_ship_self_status ? 1 : 0,
      transportType,
      isBuyNow: !!buyNowNo.value,
      casualCheckoutNo: buyNowNo.value,
    }
    return AddOnItemPop.getOpts4FreeShippingFromCheckout(activityFreeParams)
  }

  const handleAddFreeOpen = async () => {
    const propsParams  = await propsParamsResult()
    const addOnConfig = {
      ...propsParams,
      on: { // 钩子
        close: (freeInfo) => {
          model.visible = false
  
          model.addFreeStatus = isCouponType.value
            ?
            [1, 2].includes(+freeInfo?._data?.is_satisfied) ? 1 : 0
            :
            freeInfo.isActivityStop || freeInfo.isActivityStop ? 1 : 0
  
          if(model.addGoodsTimes > 0) {
            model.addGoodsTimes = 0
            handleAddOrderSuccess({
              status: model.addFreeStatus,
              addItem: freeShippingAddItem,
              mallCode,
              transportType,
            })
          }
        },
        async updateCart (cartInfo) {
          cartInfo && model.addGoodsTimes++
          await updateCartsAndCheckout({ checkout: { current_action: 'add_cart_item' } })
          const propsParams  = await propsParamsResult()
          // 仅处于打开状态才能更新, 规避券弹窗使用旧的方法更新
          AddOn?.updateOpts(propsParams)
          AddOnItemPop?.isOpened() && AddOnItemPop?.updateOpts(propsParams)
        },
        beforeOpen: () => {
        }
      },
    }
  
    model.visible = true
  
    if (isCouponType.value) {
      return AddOn.open(addOnConfig) // 券走中间层融合
    }
  
    AddOnItemPop.open(addOnConfig)
  }

  const handleAddOrderSuccess = async ({ status = '', addItem, mallCode, transportType }) => {
    // 凑单未完成
    if(+status !== 1) {
      return false
    }
  
    const beforeAddItemSelected = cloneDeep(shippingMethodState.value?.selectedShippingList)
  
    selectShippingMethod({
      mallCode,
      transportType: transportType
    })
  
    const params = getShippingMethodsParams()
  
    // ![下单页运输方式模块支持免邮活动和运费券凑单](https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1324179492)
    const res = await updateCheckoutData({
      opts: {
        // 运输方式参数
        ...params,
        current_action: 'add_cart_item',
        free_shipping_add_info: {
          mall_code: mallCode,
          transport_type: transportType,
          add_item_type: addItem.add_item_type,
          last_activity_type: addItem.njActivityType,
          last_activity_id: addItem.activity_id,
          last_coupon_code: addItem.coupon_code,
          from_popup: status,
  
          first_checkout_complete: 1,
          have_done_complete: 1,
        }
      }
    })
  
    if(+res.code === 0) {
      resetNormalOnSuccess()
      return true
    }
  
    addItemRollback(beforeAddItemSelected)
  }

  return {
    handleAddFreeOpen,
    handleAddOrderSuccess,
  }
}
