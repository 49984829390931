<template>
  <div class="checkout-address-shop">
    <Icon
      name="sui_icon_location_fill_15px"
      class="checkout-address-shop__icon"
    />
    <div
      :class="isNoShopAddressScene ? 'checkout-address-shop__wrapper_no-address' : 'checkout-address-shop__wrapper_normal'"
    >
      <AddressShopDetail
        :addressInfo="innerAddressInfo"
        :showRightIcon="showRightIcon"
        :showSensitiveTip="!addressTitleTips"
        @click="handleStoreAddressEdit"
      />

      <div
        v-if="addressTitleTips"
        v-expose="{
          id: 'expose_constomsinfo_guide:simple',
          once: true
        }"
        tabindex="0"
        class="checkout-address-shop__tips"
      >
        <p
          class="checkout-address-shop__tips_text"
          @click="e => emits('clickAddressTips', e)"
          v-html="addressTitleTips"
        ></p>
      </div>
    </div>
  </div>
</template>

<script setup name="CheckoutAddressShop">
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store.js'
import AddressShopDetail from './AddressShopDetail.vue'
import { OPERATE_TYPE, TRANSPORT_TYPE } from './../utils/constant'
import useWakeAddressWrite from './../hooks/useWakeAddressWrite.js'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient'

const { isClient } = useIsClient()
const { handleAddressDrawer } = useWakeAddressWrite()

const props = defineProps({
  showRightIcon: { type: Boolean, default: true },
  addressInfo: {
    type: Object,
    default: () => null,
  }
})

/*------ emits ------*/
const emits = defineEmits(['clickEdit', 'clickAddressTips'])

/*------ useMap*** ------*/
const { addressBarState } = useMapState(['addressBarState'])
const { isNoShopAddressScene, getDefaultAddress } = useMapGetters(['isNoShopAddressScene', 'getDefaultAddress'])

/*------ computed ------*/
const innerAddressInfo = computed(() => {
  return props.addressInfo || getDefaultAddress.value
})

const addressTitleTips = computed(() => {
  let alink = '#'

  if (isClient.value) {
    const osType = !!window?.navigator?.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'android'
    alink = window?.gbCommonInfo?.EZ_WAY_APP[osType] || ''
  }

  const nameVerifiedTip = template(alink, getDefaultAddress.value?.nameVerifiedTip)
  // return nameVerifiedTip || addressBarState.value?.nationalIdTip || addressBarState.value?.addressTip
  // 与商品相关的地址提示 > 敏感提示 > 台湾实名认证提醒 > 地址提示
  return addressBarState.value?.nationalIdTip || 
    getDefaultAddress.value?.sensitiveRule?.sensitiveTip ? false : 
    (nameVerifiedTip || addressBarState.value?.addressTip)
})

/*------ methods ------*/
/**
 * @description: 地址栏【店配区域】点击，即进入编辑店配地址的弹窗
 * @return {*}
 */
const handleStoreAddressEdit = () => {
  handleAddressDrawer({
    status: true,
    operateType: OPERATE_TYPE.EDIT,
    addressType: TRANSPORT_TYPE.SHOP,
    addressData: innerAddressInfo.value
  })
}
</script>

<style lang="less">
.checkout-address-shop {
  position: relative;
  display: flex;
  padding: 20/75rem 24/75rem;
  background: #fff;
  &:active{
    background: @sui_color_gray_weak2;
  }
  &::before {
    position: absolute;
		.left(0);
		bottom: 0;
		content: '';
		height: 3px;
		width: 100%;
		background-size: 100%;
    background-image: url(/pwa_dist/images/store-border-bg-9e28e072d4.png);
  }
  &__icon {
    .margin-r(16/75rem);
  }
  &__wrapper {
   &_normal {
     width: 94%;
   }
   &_no-address {
    width: 100%;
   }
  }
  &__tips {
    padding: 12/75rem 0 0;
    background: #fff;
		.font-dpr(24px);
    a {
      color: #1860a7;
      text-decoration: none
    }
    &_text {
      background: #F6F6F6;
      padding: 20/75rem;
      color: #666;
    }
  }

}
</style>
