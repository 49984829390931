<template>
  <div
    v-if="addressInfo && Object.keys(addressInfo).length"
    :class="[{
      'checkout-address-storedetail': true,
      'checkout-address-storedetail_default': props.showDisplayBefore,
    }]"
    role="button"
    @click="handleClick"
  >
    <div class="checkout-address-storedetail__content">
      <div class="checkout-address-storedetail__consignee">
        <div class="checkout-address-storedetail__consignee_name">
          {{ addressInfo.lname }}
          {{ addressInfo.fname }}
          <template v-if="showCountryInName">
            {{ addressInfo.value }}
          </template>
        </div>
        <div class="checkout-address-storedetail__consignee_phone">
          {{ addressInfo.tel }}
        </div>
      </div>
      <template v-if="addressInfo.countryId == COUNTRY_OR_AREA_CODE.TW">
        <p>
          <s-label
            type="neutral"
          >
            {{ language.SHEIN_KEY_PWA_16611 }}
          </s-label>
          {{ addressInfo.storeName }}
          {{ addressInfo.storeType == 1 ? '(7-ELEVEN)' : '(全家便利商店)' }}
        </p>
        <p>
          {{ addressInfo.address1 }}
          {{ addressInfo.address2 }}
          {{ addressInfo.street }}
          {{ addressInfo.district }}
          {{ addressInfo.city }}
          {{ addressInfo.state }}
          {{ addressInfo.countryName }}
        </p>
      </template>
      <template v-else>
        <p>
          <s-label
            type="neutral"
          >
            {{ language.SHEIN_KEY_PWA_16611 }}
          </s-label>
          {{ addressInfo.storeName }}
          {{ addressInfo.address1 }}
          {{ addressInfo.address2 }}
        </p>
        <p>
          {{ addressInfo.district }}
          {{ addressInfo.street }}
          {{ addressInfo.city }}
          {{ addressInfo.state }}
          {{ addressInfo.countryName }} {{ addressInfo.postcode }}
        </p>
      </template>
      <div
        v-if="addressInfo?.sensitiveRule?.sensitiveTip && props.showSensitiveTip"
        class="checkout-address-storedetail__sensitiveTip"
      >
        <div class="checkout-address-storedetail__sensitiveTip_wrap">
          <Icon
            name="sui_icon_info_14px_2"
            :size="`${28 / 75}rem`"
          />
          {{ addressInfo?.sensitiveRule?.sensitiveTip }}
        </div>
      </div>
    </div>
    <Icon
      v-if="props.showRightIcon"
      name="sui_icon_more_right_16px"
    />
  </div>
</template>

<script setup name="AddressStoreDetail">
import { Icon } from '@shein-aidc/icon-vue2'
import { Label as SLabel } from '@shein/sui-mobile'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store.js'
import { COUNTRY_OR_AREA_CODE } from '@/public/src/pages/checkout_new/utils/constant.js'

/*------ props ------*/
const props = defineProps({
  addressInfo: { type: Object, default: () => {} },
  showSensitiveTip: { type: Boolean, default: true },
  showRightIcon: { type: Boolean, default: true },
  showDisplayBefore: { type: Boolean, default: false },
  showCountryInName: { type: Boolean, default: true }
})

/*------ emits ------*/
const emits = defineEmits(['editClick'])

/*------ useMap*** ------*/
const { language } = useMapState(['language'])

const handleClick = (e) => {
  if(props.showRightIcon) {
    emits('click', e)
  }
}
</script>

<style lang="less">
.checkout-address-storedetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &_default {
    &:before {
      display: none;
    }
  }
  &__content {
    padding: 0;
    .text-overflow();
    p{
      line-height: 34/@w2-375rem;
      font-size: 28/@w2-375rem;
      margin-bottom: 8/@w2-375rem;
      color: #222;
      .text-overflow();
      .padding-r(24/@w2-375rem);
    }
  }
  &__consignee {
    display: flex;
    line-height: 38/@w2-375rem;
    font-size: 28/@w2-375rem;
    margin-bottom: 8/75rem;
    &_name {
      .margin-r(16/@w2-375rem);
      font-weight: 600;
      .text-overflow();
    }
    &_phone {
      font-size: 24/@w2-375rem;
      color: #767676;
      white-space: nowrap;
      overflow: unset;
      text-overflow: unset;
    }
  }
  &__sensitiveTip{
    margin-top: 24 / 75rem;
    padding: 16 / 75rem;
    border-radius: 8 / 75rem;
    border: 1 / 75rem solid #FFE2D3;
    background: #FFF6F3;
    color: @sui_color_unusual;
    font-family: "system-ui";
    font-size: 24 / 75rem;
    font-weight: 400;
    line-height: normal;
    &_wrap {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 超出几行省略 */
      word-break: break-all;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}
</style>
