var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _setup.prefixCls('ht__auto'),
  ]},[_vm._t("default"),_vm._v(" "),_c(_setup.ClientOnly,[_c(_setup.AnimationCarousel,{ref:"AnimationCarouselRef",class:[
        _setup.prefixCls('ht__auto-animation'),
        _vm.isShowTwoRowsTitle
          ? ''
          : _setup.prefixCls('ht__auto-animation_single')
      ]},[_vm._l(([0, 1]),function(_,index){return _c('template',{slot:`item${index}`},[_c('h2',{key:index,class:_setup.prefixCls('ht__aa_item'),style:(_setup.useGetTitleConfig(index, _setup.modelData).style || {})},[(_setup.useGetTitleConfig(index, _setup.modelData).iconUrl)?_c('img',{class:_setup.prefixCls('ht__aa-icon'),style:({
              width: _vm.isShowTwoRowsTitle ? '0.32rem' : '0.48rem',
              height: _vm.isShowTwoRowsTitle ? '0.32rem' : '0.48rem'
            }),attrs:{"src":_setup.useGetTitleConfig(index, _setup.modelData).iconUrl}}):_vm._l((_setup.allIcons),function(icon,iconIndex){return _c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.TitleConfig[_setup.modelData.titles[index]?.titleType]?.icon?.name === icon.name),expression:"TitleConfig[modelData.titles[index]?.titleType]?.icon?.name === icon.name"}],key:iconIndex,attrs:{"name":icon.name,"color":icon.color,"size":_vm.isShowTwoRowsTitle ? '12px' : icon.size}})}),_vm._v(" "),_c('div',{class:[
              _setup.prefixCls('ht__aa-text'),
              _vm.isShowTwoRowsTitle
                ? _setup.prefixCls('ht__aa-text_vice')
                : _setup.prefixCls('ht__aa-text_single')
            ]},[(_setup.useGetTitleConfig(index, _setup.modelData).before)?_c('span',{class:_setup.prefixCls('ht__aa_text-normal'),domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index, _setup.modelData).before)}}):_vm._e(),_vm._v(" "),(_setup.useGetTitleConfig(index, _setup.modelData).special)?_c('span',{class:_setup.prefixCls('ht__aa_text-normal'),domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index, _setup.modelData).special)}}):_vm._e(),_vm._v(" "),(_setup.useGetTitleConfig(index, _setup.modelData).after)?_c('span',{class:_setup.prefixCls('ht__aa_text-normal'),domProps:{"innerHTML":_vm._s(_setup.useGetTitleConfig(index, _setup.modelData).after)}}):_vm._e(),_vm._v(" "),(_setup.useGetTitleConfig(index, _setup.modelData).countdownTime)?_c(_setup.CountDown,{class:[_setup.prefixCls('ht__aa-timer'), _vm.isShowTwoRowsTitle && _setup.prefixCls('ht__aa-timer_small')],attrs:{"end-time-stamp":_setup.useGetTitleConfig(index, _setup.modelData).countdownTime,"small":_vm.isShowTwoRowsTitle}}):_vm._e()],1)],2)])})],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }