<template>
  <s-drawer 
    direction="btt" 
    :visible.sync="visibleXtraCouponModal" 
    :type="'multi'"
    max-size="90%"
    append-to-body 
    class="xtra-coupon__drawer"
    :style="{
      '--title-color': currentDrawerStyle?.titleColor,
      '--title-place-color': currentDrawerStyle?.titlePlaceColor,
      '--sub-title-color': currentDrawerStyle?.subTitleColor,
      '--sub-title-place-color': currentDrawerStyle?.subTitlePlaceColor,
      '--protocal-color': currentDrawerStyle?.protocolColor,
      '--button-color': currentDrawerStyle?.buttonColor,
    }"
    @close="closeDrawer"
    @close-from-icon="closedIcon"
    @close-from-mask="closedIcon"
  >
    <template slot="top">
      <div
        class="xtra-coupon__drawer-title"
      >
        <img
          class="xtra-coupon__drawer-title-logo"
          :src="`${PUBLIC_CDN}/pwa_dist/images/checkout/sui_img_saver_logo-f55f0a0ccb.png`"
          alt=""
        />
        <div 
          class="xtra-coupon__drawer-title-tips"
          v-html="saveTips"
        ></div>
      </div>
    </template>

    <div
      class="xtra-coupon__drawer-valid"
      v-html="validTime"
    >
    </div>

    <NewUserGifts
      v-if="!!currentAggregatedCouponList"
      class="xtra-coupon__drawer-gifts"
      :language="language"
      :current-code="currentCode"
      :xtra-aggregated-coupon-list="currentAggregatedCouponList"
    />

    <div
      v-if="!!currentCouponListDrawer.length"
      class="xtra-coupon__drawer-body"
    >
      <ProductCouponRound 
        v-for="(item, index) in currentCouponListDrawer"
        :key="index"
        :coupon-round-info="item"
        :language="language"
      />
    </div>

    <template slot="footer">
      <ProductBagSelectedDrawer
        :language="language"
        :productPackageList="productPackageList"
        :selectedCode="selectedCodeInSelected"
        :notSupport="disabled"
        @clickDiscountTips="clickDiscountTips"
        @updateProductCode="updateProductCode"
      />

      <!-- 自动续费包引导切换快捷支付方式 -->
      <AutoPayBubble
        v-if="autoRenewalBubble.isShowAutoRenewdDubble"
        ref="autoPaymenodsGuideRef"
        :productCode="currentProductCode"
        :logoList="autoRenewalBubble.logoList"
        :bubbleText="autoRenewalBubble.bubbleText"
        :options="bubbleOption"
        @bubbleClose="() => { bubbleDown = true }"
        @handlePayMethodsListDrawerShow="autoRenewdDrawerShow"
      />
      <!-- 会费券引导勾选提示 -->
      <div
        v-if="virCouponTipsShow"
        v-expose="{id: '2-42-15',data: { type: 'saver', position:'popup'} }"
        class="virCoupon-tips"
        @click="clickDiscountTips($event, currentPackage.saveCardProductCode)"
      >
        <div>
          <Icon
            name="sui_icon_coupon_discount_16px"
            size="16px"
          />
          {{ currentPackage.reducePriceLabel?.text }}
        </div>
        <div>{{ language.SHEIN_KEY_PWA_28740 }} ></div>
      </div>
      
      <div
        class="xtra-coupon__drawer-footer"
      >
        <template v-if="isShowDiscountBtn">
          <div class="xtra-coupon__drawer-footer-discount">
            <s-button
              class="xtra-coupon__drawer-btn-discount"
              :class="{ 'xtra-coupon__discount-high-color': !disabled && isShowDiscountBtnHighColor }"
              :type="['primary', 'H80PX']"
              :width="'100%'"
              :disabled="disabled"
              @click="changeBagEvent(currentProductCode)"
            >
              <span class="btn-text">
                {{ language.SHEIN_KEY_PWA_25973 }}
              </span>
              <span class="btn-price">
                {{ currentPackage.specialPriceWithSymbol }}
              </span>
              <del 
                v-if="hideSaverStrikethroughPrice && showDiscount"
                class="btn-price-del"
              >
                {{ currentPackage.priceLocalWithSymbol }}
              </del>
            </s-button>
            <div
              v-if="!isHasAutoRenewedBag && hideSaverStrikethroughPrice && !virCouponTipsShow && !!discountTips"
              class="dicount-tips"
              :style="{
                '--label-bg-color': currentDrawerStyle?.labelBgColor,
                '--label-border-color': currentDrawerStyle?.labelBorderColor,
                '--label-color': currentDrawerStyle?.labelColor,
              }"
              @click="clickDiscountTips($event, currentPackage.saveCardProductCode)"
            >
              <div :class="['triangle-border', GB_cssRight ? 'right' : '']">
                <div class="triangle"></div>
                <div class="triangle-line"></div>
              </div>
              {{ discountTips }}
            </div>
          </div>
        </template>
        <s-button
          v-else
          class="xtra-coupon__drawer-footer-normal"
          :type="['primary', 'H80PX']"
          :width="'100%'"
          :disabled="disabled"
          @click="changeBagEvent(isHasAutoRenewedBag && !selectedCode ? '' : currentProductCode)"
        >
          <span v-if="isHasAutoRenewedBag && !selectedCode">{{ language.SHEIN_KEY_PWA_29582 }}</span>
          <template v-else>
            <span class="btn-text">
              {{ language.SHEIN_KEY_PWA_25973 }}
            </span>
            <span class="btn-price">
              {{ currentPackage.specialPriceWithSymbol }}
            </span>
          </template>
        </s-button>
        <p class="xtra-coupon__drawer-footer-policy">
          <span
            @click.stop="clickPolicy"
            v-html="policyTips"
          ></span>
          <template v-if="isShowAutoRenewed">
            |
            <span
              class="auto-renewed-protocol"
              @click.stop="autoRenewalDialogVisible = true"
            >{{ language.SHEIN_KEY_PWA_31239 }}</span>
          </template>
        </p>
      </div>
    </template>
    <!-- 自动续费协议弹窗 -->
    <AutoRenewedDialog
      v-if="autoRenewalDialogVisible"
      :visible="autoRenewalDialogVisible"
      :language="language"
      :protocolTips="protocolTips"
      @closeDialog="autoRenewalDialogVisible = false"
    />
  </s-drawer>
</template>

<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { Drawer as SDrawer } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import ProductCouponRound from './ProductCouponRound.vue'
import NewUserGifts from './newUserGifts/index.vue'
import AutoPayBubble from './AutoPayBubble.vue'
import ProductBagSelectedDrawer from './ProductBagSelectedDrawer'
import { transformProudctPackageStyle } from './shared/utils.js'

const { PUBLIC_CDN, langPath, SiteUID, GB_cssRight } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '2-42' })

export default {
  name: 'SaverCouponDrawer',
  components: {
    ProductCouponRound,
    SDrawer,
    Icon,
    NewUserGifts,
    AutoPayBubble,
    ProductBagSelectedDrawer,
    AutoRenewedDialog: () => import(/* webpackChunkName: "checkout-xtra-autoRenewedDialog" */'./AutoRenewedDialog'),
  },
  props: {
    productDrawerList: {
      type: Object,
      default: () => {}
    },
    productPackageList: {
      type: Array,
      default: () => []
    },
    currentCode: {
      type: String,
      default: ''
    },
    selectedCodeInEntry: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: () => {}
    },
    showXtraCoupon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      GB_cssRight,
      isLoaded: false,
      selectedCode: '',
      autoRenewalDialogVisible: false,
      bubbleOption: {
        guideType: 'normal',
        iconShow: false
      },
      selectedCodeInSelected: '',
    }
  },
  computed: {
    visibleXtraCouponModal: {
      get () {
        const isShowCouponModal = this.showXtraCoupon && this.isLoaded
        return isShowCouponModal
      },
      set (visible) {
        this.$emit('closeCouponDrawer', visible)
      }
    },
    currentProductCode() {
      if (this.productPackageList.length == 1) {
        return this.productPackageList[0]?.saveCardProductInfo?.saveCardProductCode
      }

      return this.selectedCode || this.currentCode
    },
    currentPopup() {
      return this.productDrawerList[this.currentProductCode]?.saveCardPopup || {}
    },
    currentPackage() {
      return this.productDrawerList[this.currentProductCode]?.saveCardProductInfo || {}
    },
    currentCouponListDrawer() {
      return this.currentPopup?.couponPrivilegeCycleList || []
    },
    currentAggregatedCouponList() {
      return this.currentPopup?.activityPrivilegeInfo
    },
    currentDrawerStyle() {
      return transformProudctPackageStyle(this.currentPopup?.style_info)
    },
    autoRenewalPackage() {
      return this.productPackageList.find(item => item.saveCardProductInfo.isAutoRenewProduct == '1')?.saveCardProductInfo
    },
    autoRenewalBubble() {
      const autoRenewal = this.currentPackage?.auto_renewal || {}
      const bubble = {
        bubbleText: template(+autoRenewal?.filteredPaymentList?.length, autoRenewal?.pick_popup_tip) || '',
        logoList: autoRenewal?.filteredPaymentList?.map(v => v.icon) || [],
        isShowAutoRenewdDubble: autoRenewal?.pick_popup_show,
      }
      return bubble
    },
    isShowDiscountBtn() {
      if (this.isHasAutoRenewedBag) {
      // 多包 如果未选中就不高亮
        return !!this.selectedCode || this.selectedCodeInSelected
      }
      // 单包：有会费券才高亮
      return this.virCouponTipsShow
    },
    saveTips() {
      return template(`<em>${this.currentPopup?.equivalentValueWithSymbol}</em>`, this.currentPopup?.openGuideTip)
    },
    validTime() {
      const validTime = this.currentPopup?.componentSubTitle || {}
      return template(` <em>${validTime?.fillInfo}</em>`, validTime?.tip)
    },
    policyTips() {
      const componentProtocol = this.currentPackage?.saveCardTip?.componentProtocol

      const holderLink = componentProtocol?.holderLink
      const holderText = componentProtocol?.holderText
      const tip = componentProtocol?.tip

      const url = `${langPath}${holderLink}`

      const linker = `<a style='color: #2D68A8;text-decoration: none;' href=\"${url}\" target=\"_blank\">${holderText}</a>`
      return `${template(`<em>${linker}</em>`, `${tip}`)}`
    },
    protocolTips() {
      return this.autoRenewalPackage?.saveCardTip?.protocolTips
    },
    isHasAutoRenewedBag() {
      return this.productPackageList.some(item => item.saveCardProductInfo.isAutoRenewProduct == '1')
    },
    // 控制 isShowDelPrice 划线价开关
    hideSaverStrikethroughPrice() {      
      return this.currentPackage?.style_info?.showLinePrice
    },
    isShowDiscountBtnHighColor() {
      if (SiteUID == 'pwfr' || ['mpl', 'pwde'].includes(SiteUID) && this.hideSaverStrikethroughPrice) {
        return false
      }
      return true
    },
    isShowAutoRenewed() {
      return this.currentPackage.isAutoRenewProduct == '1'
    },
    showDiscount() {
      return +this.currentPackage?.priceLocal > +this.currentPackage?.specialPrice
    },
    virCouponTipsShow() {
      return this.currentPackage?.reducePriceLabel?.action == 'couponList'
    },
    discountTips() {
      return this.productPackageList[0]?.saveCardProductInfo?.reducePriceLabel?.text
    },
  },
  watch: {
    isShowAutoRenewed: {
      handler(val) {
        if (val && !this.isReportOnceEvent) {
          this.isReportOnceEvent = true
          daEventCenter.triggerNotice({
            daId: '2-42-12'
          })
        }
      },
      immediate: true
    },
    visibleXtraCouponModal(val) {
      if (val) {
        if (val && this.isHasAutoRenewedBag) {
          this.selectedCodeInSelected = this.selectedCodeInEntry
        }
      }
    },
  },
  mounted() {
    this.isLoaded = true
  },
  methods: {
    closeDrawer() {
      this.$emit('closeCouponDrawer', false)
    },
    closedIcon() {
      daEventCenter.triggerNotice({
        daId: '2-42-5',
        extraData: {
          click_type: 'close'
        }
      })
      if (this.isHasAutoRenewedBag) {
        // this.updateProductCode('')

      }
      this.closeDrawer()
    },
    clickDiscountTips(e, productCode) {
      this.$emit('clickDiscountTips', e, productCode)
    },
    updateProductCode(code) {
      this.selectedCode = code
    },
    autoRenewdDrawerShow(productCode) {
      this.$emit('autoRenewdDrawerShow', productCode)
    },
    clickPolicy() {
      this.$emit('clickPolicy')
      daEventCenter.triggerNotice({
        daId: '2-42-6',
        extraData: {
          click_type: 'detail'
        }
      })
    },
    changeBagEvent(productCode) {
      this.updateProductCode(productCode)
      this.$emit('changeBagEvent', productCode)
      this.visibleXtraCouponModal = false
    }
  },
}
</script>

<style lang="less">
/* stylelint-disable-next-line selector-max-specificity */
.xtra-coupon__drawer {
  .S-drawer__header_normal {
    min-height: unset;
    border-bottom: 0;
    line-height: 1;
  }
  .S-drawer__header {
    img {
      width: unset;
    }
  }
  .S-drawer__multi-footer{
    border-top: 1px solid @sui_color_gray_weak1;
    position: relative;
    padding: 0;

    .mode-item-right__origin-price {
      color: var(--line-price-color, #959595);
    }
  }
  .xtra-coupon__drawer-title {
    margin-top: 40/75rem;
    padding: 0 24/75rem;
  }
  .xtra-coupon__drawer-title-logo {
    width: 224/75rem;
    height: 48/75rem;
    margin: 0 auto;
  }
  .xtra-coupon__drawer-title-tips {
    .font-dpr(56px);
    color: var(--title-color, #240B44);
    font-style: italic;
    margin: 16/75rem auto;
    font-weight: 700;

    em {
      .font-dpr(64px);
      color: var(--title-place-color, #F82854);
      font-style: italic;
    }
  }
  .xtra-coupon__drawer-valid {
    .font-dpr(28px);
    line-height: 1.2;
    color: var(--sub-title-color, @sui_color_gray_dark3);
    text-align: center;
    font-family: SF Pro;

    em {
        color: var(--sub-title-place-color, @sui_color_gray_dark3);
    }
  }

  .xtra-coupon__drawer-gifts {
    margin: 0 24/75rem;
  }

  .xtra-coupon__drawer-body {
    padding: 0 24/75rem;
    margin-bottom: 48/75rem;
  }

  .xtra-coupon__drawer-footer {
    padding: .16rem .32rem;
    .xtra-coupon__drawer-footer-policy {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      margin-top: 16/75rem;
      text-align: left;
      .font-dpr(24px);
      color: var(--protocal-color, @sui_color_gray_dark3);
    }
    .auto-renewed-protocol {
      color: #2D68A8;
      cursor: pointer;
    }
  }

  .xtra-coupon__drawer-btn-discount {
    position: relative;
    border: 0;
    .btn-text {
      .font-dpr(28px);
      font-weight: 700;
    }
    .btn-price {
      .font-dpr(40px);
      font-weight: 700;
      margin: 0 8/75rem;
    }
    .btn-price-del {
      .font-dpr(28px);
      font-weight: 400;
    }
  }
  .xtra-coupon__discount-high-color {
    --default-bg-color: linear-gradient(270deg, #EE1442 0%, #F22BBB 61.60%, #C222FA 100%), linear-gradient(0deg, #F92654 0%, #F92654 100%), #FFF;
    background: var(--button-color, var(--default-bg-color));
  }
  .xtra-coupon__drawer-footer-discount {
    position: relative;
  }

  .xtra-coupon__drawer-footer-normal {
    .btn-text {
      .font-dpr(28px);
    }
    .btn-price {
      .font-dpr(40px);
      .margin-l(8/75rem);
    }
  }

  .xtra-coupon__drawer-footer-special {
    width: 100%;
    height: 88/75rem;
    position: relative;
  }

  .xtra-coupon__drawer-special-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;

    .special-left {
      opacity: 0.8;
      margin: 20/75rem 0 12/75rem;
      .margin-l(32/75rem);
      width: 186/75rem;
      word-break: break-word;
      .font-dpr(24px);
      font-weight: 600;

      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .special-right {
      line-height: 1;
      color: #FFF;
      font-weight: 700;
      width: 388/75rem;
      .margin-r(32/75rem);

      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .price-text {
      .font-dpr(28px);
    }
    .price-amount {
      .font-dpr(40px);
    }
  }
  .dicount-tips {
    position: absolute;
    z-index: @zindex-out;
    top: -6px;
    .right(0);
    transform: translateY(-100%);
    line-height: 1;
    border-radius: 4px;
    border: 1px solid #F7B432;
    background: #FFF1BE;
    padding: 8/75rem 12/75rem;
    color: #421609;
    text-align: center;
    .font-dpr(24px);
    font-weight: 600;
    background: var(--label-bg-color, #FFF1BE);
    border-color: var(--label-border-color, #F7B432);
    color: var(--label-color, #421609);

    .triangle-border {
        position: absolute;
        width: 100%;
        height: 120%;
        top: 0;
        .right(35px);
        z-index: 0;

        &.right {
          transform: scaleX(-1);
        }
      }
      .triangle-line {
        position: absolute;
        top: 2/75rem;
        width: 100%;
        height: 100%;
        background: var(--label-border-color, #F7B432);
        clip-path: polygon(calc(100% - 26px) 83%, calc(100% - 14px) 83%, calc(100% - 20px) calc(100% + 1px), calc(100% - 20px) calc(100% + 1px));
        z-index: -1;
      }
      .triangle {
        width: 0;
        height: 0;
        width: 100%;
        height: 100%;
        background: var(--label-bg-color, #FFF1BE);
        clip-path: polygon(calc(100% - 25px) 83%, calc(100% - 15px) 83%, calc(100% - 20px) 100%, calc(100% - 20px) 100%);
      }
  }

  .virCoupon-tips{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    background: #FFD7E0;
    color: #EB3662;
    padding: 0.16rem 0.32rem;
    // margin-top: 5/37.5rem;
  }
}
</style>
