<template>
  <Popover 
    v-model="delectBubbleVisible" 
    placemen="top" 
    trigger-type="user"
    :custom-class="prefixCls('mp__item_popover_container')"
  >
    <div v-if="delectBubbleInfo">
      <div
        :id="data.id"
        v-expose="{
          id: 'expose_ugctags_bubble:simple',
          data: delectBubblePoint,
          delay: 0
        }"
        :class="prefixCls('mp__item_popover_text')"
        :style="bubbleStyle"
      >
        <img :src="delectBubbleInfo.icon" />
        <span v-html="delectBubbleInfo.text"></span>
        <Icon @handleCloseIconClick="close" name="sui_icon_close_16px_2" size="16px" :class="prefixCls('mp__item_popover_close')" />
      </div>
    </div>
    
    <div
      slot="reference"
      :class="[
        prefixCls('mp__item'),
        visibleTogetherDynamic && 'shopping-bag-dynamic__body-item'
      ]"
    >
      <div :class="prefixCls('mp__item-section')">
        <!--布局需要-->
        <div :class="prefixCls('mp__is-images')">
          <CommonImage
            :imgUrl="data?.product?.goods_img"
            :imgDesignWidth="111"
            :isFirstPage="isFirstScreenRender"
            :class="isFirstScreenRender ? 'fsp-element' : ''"
            :fetchpriority="isFirstScreenRender ? 'high' : 'auto'"
            :fsp-key="
              isFirstScreenRender
                ? `${prefixCls('mp__is-main-image')}_${data?.id}`
                : null
            "
            :imageMask="data?.product?.maskLayerImg"
          >
            <template #others>
              <!-- 敏感品 icon -->
              <Sensitive :id="data.id" />

              <!-- 腰带 -->
              <Belt
                v-if="beltInfo.isShow"
                :data="data"
                :renderWidth="renderWidth"
              />
            </template>
          </CommonImage>
        </div>
        <!-- 价格 -->
        <Price :data="data" />
        <!-- warning警示信息[p65化学危险品标识] -->
        <P65Warning
          v-if="isShowP65Warning"
          :class="prefixCls('mp__warning')"
          :goods-sn="data?.product?.goods_sn"
        />
      </div>

      <!-- 商品数输入框 -->
      <QuantityInput
        :class="prefixCls('mp__input-number')"
        :data="data || {}"
        @emitInputCurrent="inputNumberChange"
      />
    </div>
  </Popover>
</template>

<script>
import { Popover } from '@shein/sui-mobile'
 
export default {
  components: {
    Popover
  }
}
</script>

<script setup name="ProductItem">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

import Price from './Price.vue'
import QuantityInput from '../../common/QuantityInput.vue'
import CommonImage from '@/public/src/pages/checkout_new/components/CommonImage.vue'
import Belt from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/Belt.vue'
import P65Warning from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/P65Warning.vue'

import useP65warningState from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useP65warningState.js'
import useBeltInfo from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useBeltInfo.js'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import Sensitive from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/Sensitive.vue'
import { useDelectBubble } from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useParticles.js'

import { useMapState } from 'public/src/pages/checkout_new/hooks/store.js'
const { togetherBuy } = useMapState(['togetherBuy', 'checkoutLoading'])

const renderWidth = 148 / 75 // 148/75rem UI的尺寸
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  }
})

// --------- useMap_**** --------

const { beltInfo } = useBeltInfo(props)
const { isShowP65Warning } = useP65warningState(props.data.product.goods_sn)
const { delectBubbleVisible, bubbleStyle, changeDelectBubble, delectBubbleInfo, close, delectBubblePoint } = useDelectBubble(props)

// ---------- computed ----------
const isFirstScreenRender = computed(() => {
  // 首屏预计渲染购物袋图片数量
  const firstScreenRenderNum = 5
  return props.index < firstScreenRenderNum
})

const visibleTogetherDynamic = computed(() => {
  const result = props.data.id == togetherBuy.value.currentCartId

  return result
})

const inputNumberChange = (currentValue, oldValue, type) => {
  if((type === 'decrease' || type === 'input') && currentValue > 0 && currentValue < oldValue){
    setTimeout(() => {
      changeDelectBubble(true, props.data.id)
    }, 300)  
  }
}

</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}mp__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8/75rem;
  background: rgba(0, 0, 0, 0.03);
  overflow: hidden;
  flex-shrink: 0;
  width: 148/75rem;
  background: none;

  &:last-child {
    margin-right: 0.32rem;
  }

  .@{prefixCls}mp__item-section {
    position: relative;
  }

  .@{prefixCls}mp {
    &__is {
      &-images {
        position: relative;
        width: 1.97333333rem;
        height: 1.97333333rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .@{prefixCls}product__shipping-icon {
          top: 14/75rem;
        }

        .checkout-component__image {
          width: 100%;
        }
      }

      &-main-image {
        width: 100%;
      }

      &-product-shipping {
        width: 32/75rem;
        height: 32/75rem;
        position: absolute;
        top: 8/75rem;
        right: 8/75rem;
      }
    }

    &__warning {
      width: 148/75rem;
      margin-top: 8/75rem;
      margin-bottom: 0;
      padding: 3/75rem 8/75rem;
    }

    &__input-number {
      margin-top: 8/75rem;
    }
  }
}

.shopping-bag-dynamic__body-item {
  width: 0;
  opacity: 0;
  height: 0;
}
.@{prefixCls}mp__item_popover_container{
  .@{prefixCls}mp__item_popover_text{
    min-width: 100/37.5rem;
    max-width: var(--max-width, 280/37.5rem);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 设置最大显示的行数 */
    word-break: break-word; /* 防止单词在换行时被分割 */
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    img{
      width: 14/37.5rem;
      margin-right: 2/37.5rem;
      margin-bottom: 2/37.5rem;
    }
  }
  .S-popover__main-content{
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.8);
    padding-right: 20/37.5rem
  }
  &::before{
    background: none;
    transform: none;
    position: absolute;
    width: 0 !important;
    height: 0 !important;
    bottom: -0.24rem !important;
    border-left: 7.5/37.5rem solid transparent;
    border-right: 7.5/37.5rem solid transparent;
    border-top: 11/37.5rem solid rgba(0, 0, 0, 0.70);
  }
  .@{prefixCls}mp__item_popover_close{
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
